// Import React and necessary hooks
import React, { useState, useEffect } from "react";
// Import components and registration function from react-chartjs-2 and chart.js
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

// Register the chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const WeatherChart = () => {
  // State to store fetched weather data
  const [fetchedData, setFetchedData] = useState(null);
  // State to store processed data for the chart
  const [chartData, setChartData] = useState({ datasets: [] });
  // State to control the hour interval for displaying data
  const [hourInterval, setHourInterval] = useState(2);

  // Fetch weather data on component mount
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=61.19&lon=7.21",
        {
          headers: {
            "User-Agent": "YourApp/1.0 (yourname@example.com)",
          },
        }
      );
      const data = await response.json();
      setFetchedData(data); // Store the fetched data
    };

    fetchData();
  }, []);

  // Process and filter the fetched data based on the selected hour interval
  useEffect(() => {
    if (!fetchedData) return; // Ensure there is data to process

    const filteredSeries = fetchedData.properties.timeseries.filter(
      (_, index) => index % hourInterval === 0
    );

    const times = filteredSeries.map((series) => {
      const date = new Date(series.time);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`;
    });
    const temperatures = filteredSeries.map(
      (series) => series.data.instant.details.air_temperature
    );
    const windSpeeds = filteredSeries.map(
      (series) => series.data.instant.details.wind_speed
    );

    setChartData({
      labels: times,
      datasets: [
        {
          label: "Temperature (°C)",
          data: temperatures,
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Wind Speed (m/s)",
          data: windSpeeds,
          fill: false,
          borderColor: "rgb(54, 162, 235)",
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          yAxisID: "y1",
        },
      ],
    });
  }, [fetchedData, hourInterval]); // Re-process when fetchedData or hourInterval changes

  const options = {
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "xy", // Enable panning in both directions
        },
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with pinch gestures on touch devices
          },
          mode: "xy", // Enable zooming in both directions
        },
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Temperature (°C)",
          color: "#ff6384", // Optional: color of the title, you can adjust this
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // This ensures that the grid lines for y1 do not appear on the chart area, making it clearer
        },
        title: {
          display: true,
          text: "Wind Speed (m/s)",
          color: "#36a2eb", // Optional: color of the title, you can adjust this
        },
      },
    },
  };
  return (
    <div>
      <h2>Weather Forecast</h2>
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="hourIntervalSlider">
          Hour Interval: {hourInterval}
        </label>
        <input
          id="hourIntervalSlider"
          type="range"
          min="1"
          max="24"
          value={hourInterval}
          onChange={(e) => setHourInterval(Number(e.target.value))}
          style={{ marginLeft: "10px" }}
        />
      </div>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default WeatherChart;
